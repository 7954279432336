import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
		<path d="M4108 8053 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M3488 8043 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4228 8043 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3428 8033 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4138 8033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3328 8013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3488 8013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4383 8013 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4438 8003 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3158 7973 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3110 7960 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M4632 7944 c-14 -15 -7 -17 22 -5 15 6 16 9 5 13 -8 3 -20 -1 -27 -8z"/>
<path d="M3005 7930 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M4796 7892 c-2 -4 3 -8 12 -8 9 -1 26 -4 37 -8 13 -5 11 -2 -5 8 -29
18 -37 19 -44 8z"/>
<path d="M2855 7869 c-16 -10 -25 -19 -20 -19 6 0 24 9 40 20 17 11 26 20 20
19 -5 0 -23 -9 -40 -20z"/>
<path d="M4880 7861 c0 -8 41 -23 47 -17 2 1 -8 8 -22 14 -14 6 -25 8 -25 3z"/>
<path d="M3928 7823 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4980 7816 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4168 7793 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4258 7793 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5030 7790 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M2665 7780 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M3798 7753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3733 7743 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5120 7743 c0 -9 52 -35 58 -29 2 3 -10 12 -27 21 -17 8 -31 12 -31
8z"/>
<path d="M2548 7720 c-20 -11 -40 -25 -44 -31 -5 -9 73 31 95 48 14 10 -20 -1
-51 -17z"/>
<path d="M4878 7713 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4920 7706 c0 -2 9 -6 20 -9 11 -3 20 -1 20 4 0 5 -9 9 -20 9 -11 0
-20 -2 -20 -4z"/>
<path d="M2429 7643 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M5105 7650 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M5160 7630 c8 -5 22 -9 30 -9 10 0 8 3 -5 9 -27 12 -43 12 -25 0z"/>
<path d="M3308 7623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5250 7620 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5300 7626 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M2380 7610 c-12 -7 -10 -9 7 -7 12 0 19 5 17 9 -6 10 -6 10 -24 -2z"/>
<path d="M3211 7589 c-13 -5 -21 -12 -18 -15 3 -3 18 1 34 10 31 17 21 20 -16
5z"/>
<path d="M3160 7560 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5400 7560 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M3970 7530 c-279 -79 -525 -191 -745 -341 -313 -213 -514 -424 -744
-784 -40 -63 -140 -268 -176 -360 -14 -38 -33 -86 -41 -105 -26 -63 -92 -347
-109 -467 -22 -156 -22 -484 -1 -648 47 -354 191 -732 396 -1040 157 -235 311
-400 561 -600 16 -13 186 -128 225 -152 58 -37 304 -155 399 -191 408 -157
893 -202 1315 -122 145 28 484 128 390 116 -232 -31 -581 -31 -760 -2 -319 53
-633 164 -869 307 -34 22 -65 39 -67 39 -9 0 -174 117 -244 173 -109 87 -335
319 -417 428 -262 348 -417 721 -484 1163 -24 164 -26 491 -4 647 96 653 405
1207 895 1606 142 116 398 275 510 319 93 35 75 43 -30 14z"/>
<path d="M5452 7524 c10 -10 20 -15 24 -12 3 4 -5 12 -18 18 -22 12 -22 12 -6
-6z"/>
<path d="M5480 7496 c0 -7 82 -57 86 -53 2 2 -14 16 -36 31 -38 25 -50 30 -50
22z"/>
<path d="M2995 7480 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2190 7461 c0 -10 19 -25 25 -20 1 2 -3 10 -11 17 -8 7 -14 8 -14 3z"/>
<path d="M2910 7430 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2095 7384 c-30 -27 -32 -31 -9 -16 28 19 62 53 53 52 -2 -1 -22 -17
-44 -36z"/>
<path d="M2840 7390 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2754 7335 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z"/>
<path d="M2036 7324 c-23 -23 -20 -30 4 -9 11 10 20 20 20 22 0 7 -9 2 -24
-13z"/>
<path d="M1967 7255 c-15 -19 -25 -35 -21 -35 7 0 28 25 45 53 15 27 7 20 -24
-18z"/>
<path d="M1873 7155 c-15 -16 -16 -25 -5 -25 4 0 13 9 20 20 14 23 3 26 -15 5z"/>
<path d="M2520 7155 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M1835 7119 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2455 7095 c-22 -19 -35 -34 -29 -35 5 0 25 16 44 35 43 45 36 45
-15 0z"/>
<path d="M1804 7067 c-11 -13 -23 -31 -27 -40 -3 -9 -11 -17 -17 -17 -5 0 -10
-5 -10 -10 0 -7 6 -7 20 0 11 6 20 15 20 21 0 6 9 24 21 40 24 35 21 38 -7 6z"/>
<path d="M2400 7039 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2353 6998 c-34 -38 -38 -55 -4 -20 29 31 36 42 28 42 -2 0 -13 -10
-24 -22z"/>
<path d="M2287 6933 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M2130 6730 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M1575 6719 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M2096 6683 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1550 6669 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"/>
<path d="M2036 6611 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M1506 6595 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M1466 6501 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M1970 6490 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M1416 6374 c-4 -9 -4 -19 -1 -22 2 -3 7 3 11 12 4 9 4 19 1 22 -2 3
-7 -3 -11 -12z"/>
<path d="M1895 6359 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M1397 6326 c-4 -10 -3 -15 3 -11 6 3 10 1 10 -6 0 -7 3 -10 6 -6 3 3
2 13 -4 22 -8 16 -9 16 -15 1z"/>
<path d="M1860 6295 c-7 -14 -11 -28 -8 -31 3 -3 13 9 21 25 18 37 6 42 -13 6z"/>
<path d="M1381 6283 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M1791 6124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1325 6090 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1770 6075 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M1341 6014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1301 5974 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1725 5951 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M1712 5891 c-17 -48 -14 -63 4 -22 8 19 13 39 11 45 -2 6 -9 -5 -15
-23z"/>
<path d="M4475 5643 c-10 -54 -21 -102 -26 -107 -5 -6 -18 -1 -32 12 -30 29
-99 30 -145 2 -76 -46 -126 -185 -104 -289 17 -78 60 -121 120 -121 28 0 57 8
78 21 l34 21 0 -22 c0 -19 5 -21 47 -18 l47 3 18 100 c48 267 78 447 78 470 0
23 -3 25 -49 25 l-49 0 -17 -97z m-45 -179 c12 -12 12 -23 2 -72 -7 -32 -12
-71 -12 -86 0 -66 -100 -113 -134 -64 -18 26 -21 105 -6 160 15 52 63 91 104
82 17 -3 38 -12 46 -20z"/>
<path d="M4895 5718 c-19 -87 -25 -117 -25 -145 0 -42 -14 -49 -46 -24 -37 29
-96 27 -149 -3 -115 -68 -143 -325 -41 -387 53 -32 137 -20 168 25 4 6 8 -1 8
-16 0 -26 3 -28 44 -28 50 0 45 -9 66 120 6 36 15 85 20 110 12 55 60 340 60
358 0 8 -17 12 -50 12 -43 0 -50 -3 -55 -22z m-70 -251 l27 -23 -18 -89 c-18
-93 -35 -120 -79 -131 -62 -15 -91 30 -78 124 14 114 86 172 148 119z"/>
<path d="M3395 5673 c-3 -21 -10 -58 -15 -83 -5 -25 -23 -130 -41 -235 -17
-104 -31 -194 -32 -200 -1 -6 50 -10 138 -10 132 0 143 2 195 27 45 22 60 36
78 73 12 25 22 60 22 77 0 35 -32 93 -57 102 -13 6 -10 11 19 31 74 50 89 153
29 207 -42 38 -89 48 -218 48 l-112 0 -6 -37z m247 -70 c25 -22 23 -67 -6
-100 -21 -24 -32 -28 -95 -31 -80 -5 -76 -10 -59 83 l13 65 64 0 c46 0 70 -5
83 -17z m-22 -233 c15 -23 12 -73 -5 -97 -9 -12 -32 -27 -53 -33 -50 -14 -132
-13 -132 1 0 28 25 144 31 149 17 12 150 -5 159 -20z"/>
<path d="M5782 5675 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M1257 5669 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M6633 5653 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6690 5645 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M5965 5630 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6612 5620 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6631 5614 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1627 5613 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M6020 5585 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6680 5570 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1642 5545 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3891 5548 c-41 -20 -81 -67 -81 -95 0 -9 18 -13 55 -13 40 0 55 4
55 14 0 22 28 39 62 38 36 -1 48 -15 48 -59 0 -33 0 -33 -48 -33 -27 0 -71 -7
-99 -15 -82 -24 -113 -63 -113 -138 0 -39 5 -49 35 -76 29 -25 44 -31 83 -31
29 0 59 8 80 20 32 20 32 20 32 0 0 -18 7 -20 55 -20 l55 0 -6 29 c-3 15 -1
53 4 82 32 167 35 213 18 247 -35 68 -149 93 -235 50z m123 -216 c10 -16 -15
-81 -37 -97 -12 -8 -38 -15 -59 -15 -31 0 -39 4 -44 24 -8 33 11 65 49 81 36
16 83 19 91 7z"/>
<path d="M5100 5543 c-46 -24 -60 -38 -84 -86 -78 -153 -21 -303 119 -315 66
-5 110 9 154 50 97 91 102 273 10 346 -20 17 -47 25 -87 29 -49 4 -65 1 -112
-24z m135 -78 c21 -20 25 -34 25 -79 0 -102 -42 -166 -110 -166 -23 0 -35 7
-51 33 -37 59 -8 191 50 222 38 21 59 18 86 -10z"/>
<path d="M6111 5560 c0 -8 4 -24 9 -35 5 -13 9 -14 9 -5 0 8 -4 24 -9 35 -5
13 -9 14 -9 5z"/>
<path d="M6358 5563 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6975 5555 c21 -8 41 -12 44 -10 5 6 -47 25 -67 25 -9 -1 1 -7 23
-15z"/>
<path d="M6278 5553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6400 5556 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M6538 5530 c-3 -16 -1 -30 4 -30 4 0 8 11 8 24 0 13 6 27 13 29 7 4
6 6 -4 6 -11 1 -18 -9 -21 -29z"/>
<path d="M6742 5530 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M6854 5550 c-36 -14 -39 -31 -4 -19 16 6 30 15 30 20 0 5 -1 9 -2 8
-2 0 -13 -4 -24 -9z"/>
<path d="M1253 5490 c0 -30 2 -43 4 -27 2 15 2 39 0 55 -2 15 -4 2 -4 -28z"/>
<path d="M7040 5530 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6328 5513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6196 5494 c-8 -21 -8 -34 2 -34 4 0 9 11 10 25 2 28 -3 32 -12 9z"/>
<path d="M6370 5506 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M6661 5494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6975 5500 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M6123 5465 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M6264 5481 c-5 -5 -18 -11 -29 -14 -15 -4 -14 -5 7 -6 15 0 30 4 33
10 9 14 1 21 -11 10z"/>
<path d="M7071 5474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5742 5460 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1623 5425 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M6582 5445 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6662 5450 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5812 5410 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M6032 5420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7012 5420 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7071 5424 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5726 5381 c-4 -24 -5 -46 -2 -48 7 -7 19 63 14 79 -3 7 -8 -7 -12
-31z"/>
<path d="M6111 5404 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6985 5410 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6288 5393 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M6883 5393 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M6220 5380 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M6805 5375 l-30 -14 30 6 c17 3 34 10 40 14 14 13 -5 10 -40 -6z"/>
<path d="M6015 5370 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6300 5376 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M6988 5373 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1621 5354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5802 5340 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6837 5333 c-11 -13 -17 -26 -14 -29 2 -3 12 7 21 22 23 33 17 39 -7
7z"/>
<path d="M6751 5322 c-6 -12 -11 -27 -11 -34 0 -7 7 0 15 16 9 16 13 31 11 33
-2 3 -9 -4 -15 -15z"/>
<path d="M6381 5314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5711 5294 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5787 5291 c-4 -17 -3 -21 5 -13 5 5 8 16 6 23 -3 8 -7 3 -11 -10z"/>
<path d="M6370 5280 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6776 5277 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M1263 5235 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M1621 5264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5920 5259 c-19 -11 -30 -19 -23 -19 19 0 63 22 63 32 0 4 -1 7 -2 7
-2 -1 -19 -10 -38 -20z"/>
<path d="M6026 5254 c-15 -14 -26 -27 -24 -29 4 -4 58 42 58 50 0 8 -4 6 -34
-21z"/>
<path d="M6344 5245 c-10 -8 -28 -15 -39 -15 -12 -1 -15 -4 -7 -7 16 -6 72 16
72 28 0 12 -3 11 -26 -6z"/>
<path d="M6551 5244 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5702 5225 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6640 5239 c0 -5 9 -9 20 -9 11 0 20 2 20 4 0 2 -9 6 -20 9 -11 3
-20 1 -20 -4z"/>
<path d="M6832 5235 c9 -10 21 -16 28 -13 7 2 -1 10 -16 17 -27 12 -28 11 -12
-4z"/>
<path d="M5828 5233 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6688 5215 c-3 -14 -1 -25 3 -25 5 0 9 11 9 25 0 14 -2 25 -4 25 -2
0 -6 -11 -8 -25z"/>
<path d="M6781 5211 c-10 -18 -6 -31 10 -31 4 0 5 10 1 22 -3 14 -2 19 6 14 7
-4 12 -2 12 3 0 18 -18 13 -29 -8z"/>
<path d="M6378 5193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6592 5185 c9 -10 21 -16 28 -13 7 2 -1 10 -16 17 -27 12 -28 11 -12
-4z"/>
<path d="M6638 5193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6904 5184 c-18 -14 -18 -15 4 -4 12 6 22 13 22 15 0 8 -5 6 -26 -11z"/>
<path d="M6210 5180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5853 5173 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6268 5173 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6393 5173 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M6640 5170 c0 -12 8 -12 35 0 18 8 17 9 -7 9 -16 1 -28 -3 -28 -9z"/>
<path d="M1301 4984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1623 4920 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
<path d="M1330 4825 c4 -20 11 -39 14 -43 9 -8 -5 62 -15 72 -4 5 -4 -8 1 -29z"/>
<path d="M4676 4802 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z"/>
<path d="M4800 4803 c5 -2 10 -9 10 -14 0 -6 -4 -7 -10 -4 -5 3 -10 1 -10 -5
0 -7 6 -10 13 -7 16 5 16 30 -1 33 -7 1 -8 0 -2 -3z"/>
<path d="M4634 4779 c-3 -6 -1 -16 6 -22 10 -9 12 -8 7 5 -3 9 -1 19 6 22 9 3
9 5 0 5 -6 1 -15 -4 -19 -10z"/>
<path d="M3885 4770 c-4 -7 3 -8 22 -4 38 9 42 14 10 14 -14 0 -29 -5 -32 -10z"/>
<path d="M5603 4773 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5768 4773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5861 4764 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6521 4754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6583 4743 c1 -18 6 -28 10 -24 4 4 3 18 -2 32 -9 23 -9 22 -8 -8z"/>
<path d="M1611 4734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4621 4734 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5650 4731 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M6747 4723 c-4 -3 -7 -16 -6 -27 0 -19 1 -19 10 3 10 24 8 36 -4 24z"/>
<path d="M6512 4705 c0 -11 3 -29 8 -40 11 -25 11 1 0 35 -6 20 -8 21 -8 5z"/>
<path d="M4968 4702 c-14 -2 -32 -10 -40 -19 -12 -15 -11 -15 9 -5 28 15 66
15 81 0 9 -9 12 -8 12 4 0 18 -22 25 -62 20z"/>
<path d="M5920 4702 c-47 -7 -39 -17 15 -18 29 0 52 3 50 7 -7 10 -37 15 -65
11z"/>
<path d="M6197 4699 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M6897 4700 c-34 -10 -54 -26 -69 -54 -8 -15 -1 -12 26 12 20 17 43
32 51 32 7 0 17 5 20 10 3 6 5 10 3 9 -2 0 -16 -5 -31 -9z"/>
<path d="M7134 4683 c-4 -13 -4 -27 0 -30 3 -4 6 3 6 15 0 16 7 21 33 24 l32
3 -33 6 c-28 5 -33 2 -38 -18z"/>
<path d="M4310 4686 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21 -5
0 -10 -6 -10 -14z"/>
<path d="M4420 4684 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M4500 4690 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M4573 4693 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4648 4693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4738 4693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5157 4681 c-10 -10 -20 -36 -22 -57 l-5 -39 10 35 c6 19 22 45 36
58 14 12 19 22 12 21 -7 0 -21 -8 -31 -18z"/>
<path d="M5320 4685 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M6113 4675 c-15 -13 -23 -25 -16 -25 6 0 15 5 19 11 3 6 20 17 38 25
26 11 27 13 9 14 -12 0 -35 -11 -50 -25z"/>
<path d="M6600 4686 c0 -8 4 -17 9 -20 5 -4 7 3 4 14 -6 23 -13 26 -13 6z"/>
<path d="M6948 4693 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7088 4693 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5270 4680 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6230 4679 c0 -5 4 -7 10 -4 5 3 12 -6 16 -20 3 -14 10 -25 15 -25
13 0 11 13 -7 38 -16 23 -34 29 -34 11z"/>
<path d="M4767 4661 c-2 -10 0 -25 5 -32 8 -11 9 -11 5 1 -2 8 0 23 5 33 5 10
5 17 0 17 -6 0 -12 -9 -15 -19z"/>
<path d="M5850 4669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M1382 4650 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3900 4660 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4897 4663 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M5626 4648 c-3 -13 -5 -32 -5 -43 1 -11 5 -2 10 19 10 42 6 62 -5 24z"/>
<path d="M4302 4630 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5298 4653 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6995 4651 c-3 -5 -2 -12 3 -15 5 -3 9 1 9 9 0 17 -3 19 -12 6z"/>
<path d="M7112 4645 c0 -11 3 -29 8 -40 11 -25 11 1 0 35 -6 20 -8 21 -8 5z"/>
<path d="M3950 4640 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6615 4639 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4142 4620 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5041 4624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6731 4624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5302 4600 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6066 4592 c-4 -18 -5 -36 -2 -39 3 -4 6 3 6 14 0 19 7 21 92 22 51
1 95 4 98 7 3 3 -37 4 -87 2 -76 -2 -93 0 -96 12 -2 8 -7 0 -11 -18z"/>
<path d="M6271 4604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4411 4594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4750 4600 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5021 4595 c-1 -11 -4 -31 -6 -45 l-6 -25 11 25 c6 13 9 33 7 45 -4
19 -4 19 -6 0z"/>
<path d="M5971 4594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4861 4584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6801 4584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6877 4593 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M6998 4593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4041 4574 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4286 4575 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2 0
-6 -7 -10 -15z"/>
<path d="M5811 4583 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M6722 4570 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7101 4574 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4133 4540 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M4741 4564 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5128 4565 c-6 -28 20 -65 46 -64 14 1 16 3 5 6 -15 4 -32 29 -42 63
-3 11 -5 10 -9 -5z"/>
<path d="M6637 4573 c-4 -3 -7 -13 -7 -22 1 -13 3 -13 11 2 11 19 8 33 -4 20z"/>
<path d="M4396 4557 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M6081 4563 c0 -6 -4 -20 -7 -30 -5 -14 -4 -15 5 -7 7 7 11 20 8 30
-3 11 -5 14 -6 7z"/>
<path d="M1416 4545 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M5288 4533 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
<path d="M5615 4520 c-4 -25 -11 -38 -17 -33 -7 4 -8 1 -3 -6 4 -7 11 -9 15
-4 13 13 29 83 20 83 -5 0 -11 -18 -15 -40z"/>
<path d="M6492 4540 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4030 4535 c-7 -9 -10 -18 -7 -21 3 -3 10 4 17 15 13 25 8 27 -10 6z"/>
<path d="M4266 4534 c-9 -24 -2 -26 12 -4 7 11 8 20 3 20 -5 0 -12 -7 -15 -16z"/>
<path d="M6651 4528 c-1 -15 4 -30 9 -33 12 -7 12 12 0 40 -8 18 -9 17 -9 -7z"/>
<path d="M1691 4524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3830 4530 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M7092 4520 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3146 4517 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4582 4505 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5791 4514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6466 4508 c-6 -26 8 -46 19 -27 5 8 4 10 -4 5 -8 -6 -10 0 -5 18 3
14 4 26 1 26 -2 0 -7 -10 -11 -22z"/>
<path d="M6090 4512 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M6681 4504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3985 4500 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4230 4500 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4382 4490 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5000 4490 c0 -19 3 -21 12 -12 9 9 9 15 0 24 -9 9 -12 7 -12 -12z"/>
<path d="M5215 4500 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M6205 4500 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M6701 4494 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6950 4500 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M3898 4493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4841 4484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3171 4482 c-1 -21 19 -53 30 -47 5 4 9 12 9 18 0 8 -3 7 -9 -2 -6
-10 -11 -6 -19 15 -6 16 -11 23 -11 16z"/>
<path d="M4190 4479 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5276 4477 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M6135 4480 c3 -5 16 -10 28 -10 18 0 19 2 7 10 -20 13 -43 13 -35 0z"/>
<path d="M6870 4480 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M6900 4480 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M1711 4444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5253 4434 c-6 -13 -7 -24 -3 -24 9 0 25 39 18 45 -2 2 -8 -7 -15
-21z"/>
<path d="M5110 4420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M3225 4400 c-6 -10 19 -60 31 -60 4 0 -1 12 -11 26 -17 27 -12 50 6
24 9 -13 10 -13 7 -1 -5 19 -24 25 -33 11z"/>
<path d="M5145 4400 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M1736 4355 c8 -37 14 -45 14 -19 0 10 -5 26 -10 34 -8 11 -9 7 -4
-15z"/>
<path d="M3275 4360 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M3330 4300 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1761 4284 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3356 4263 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M1550 4251 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M1766 4243 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3375 4240 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3336 4213 c20 -36 34 -43 34 -16 0 9 -3 14 -6 10 -4 -3 -15 4 -26
16 -19 22 -19 22 -2 -10z"/>
<path d="M1570 4210 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3380 4163 c0 -7 10 -22 23 -35 12 -13 17 -17 11 -8 -6 8 -16 24 -22
35 -6 11 -12 15 -12 8z"/>
<path d="M1810 4151 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M3490 4140 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1620 4131 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M3520 4126 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M1850 4040 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3494 4017 c16 -20 30 -37 33 -37 7 0 -36 58 -49 66 -7 4 1 -9 16
-29z"/>
<path d="M3640 4021 c0 -9 30 -24 36 -18 2 1 -6 8 -16 15 -11 7 -20 8 -20 3z"/>
<path d="M1870 4010 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1890 3971 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M3540 3971 c0 -5 7 -15 17 -22 15 -13 15 -12 4 9 -12 23 -21 28 -21
13z"/>
<path d="M7145 3960 c-10 -11 -14 -20 -8 -20 5 0 18 9 28 20 10 11 14 20 8 20
-5 0 -18 -9 -28 -20z"/>
<path d="M3740 3946 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M7045 3890 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M3840 3883 c0 -10 40 -36 46 -30 3 2 -7 12 -21 21 -14 9 -25 13 -25
9z"/>
<path d="M1970 3830 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M3680 3826 c14 -15 34 -33 45 -39 18 -9 19 -9 6 7 -8 9 -28 27 -45
38 l-31 22 25 -28z"/>
<path d="M3788 3843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1841 3814 c13 -17 26 -30 28 -27 3 2 -8 16 -24 31 l-28 27 24 -31z"/>
<path d="M3950 3810 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M6986 3805 c-11 -8 -17 -17 -14 -20 2 -3 13 4 23 15 21 23 18 25 -9
5z"/>
<path d="M6855 3780 c-16 -11 -25 -20 -20 -20 10 0 64 39 55 40 -3 0 -18 -9
-35 -20z"/>
<path d="M4027 3769 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M2016 3757 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3794 3731 c20 -22 26 -25 26 -13 0 4 -10 13 -22 21 -23 14 -23 14
-4 -8z"/>
<path d="M4090 3730 c8 -5 22 -10 30 -10 13 0 13 1 0 10 -8 5 -22 10 -30 10
-13 0 -13 -1 0 -10z"/>
<path d="M6764 3725 c-10 -8 -14 -14 -9 -14 6 0 19 6 29 14 11 8 15 15 10 15
-6 0 -19 -7 -30 -15z"/>
<path d="M2040 3712 c6 -13 14 -21 18 -18 3 4 -2 14 -12 24 -18 16 -18 16 -6
-6z"/>
<path d="M6885 3719 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M1945 3700 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M6700 3700 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M3861 3677 c2 -2 20 -14 39 -26 l35 -23 -28 26 c-15 14 -33 26 -39
26 -6 0 -9 -1 -7 -3z"/>
<path d="M6764 3665 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z"/>
<path d="M6799 3653 c-13 -17 -13 -17 6 -3 11 8 22 16 24 17 2 2 0 3 -6 3 -6
0 -17 -8 -24 -17z"/>
<path d="M4260 3650 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M6608 3653 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4310 3630 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M6558 3633 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2110 3625 c0 -5 10 -21 21 -35 13 -17 17 -18 10 -5 -17 33 -31 51
-31 40z"/>
<path d="M6485 3610 c-13 -5 -14 -9 -5 -9 8 0 24 4 35 9 13 5 14 9 5 9 -8 0
-24 -4 -35 -9z"/>
<path d="M4388 3603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6710 3590 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4465 3570 c28 -12 37 -12 30 0 -3 6 -16 10 -28 9 -21 0 -21 -1 -2
-9z"/>
<path d="M6385 3570 c-7 -12 2 -12 30 0 19 8 19 9 -2 9 -12 1 -25 -3 -28 -9z"/>
<path d="M6668 3563 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4518 3553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6348 3553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4590 3536 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2192 3510 c7 -11 17 -20 22 -20 5 0 1 9 -9 20 -10 11 -20 20 -22 20
-2 0 2 -9 9 -20z"/>
<path d="M2147 3500 c10 -11 19 -20 21 -20 1 0 -2 9 -8 20 -6 11 -15 20 -20
20 -6 0 -2 -9 7 -20z"/>
<path d="M4728 3493 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6138 3493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4180 3476 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4828 3473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6038 3473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6495 3470 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4873 3463 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5945 3460 c-27 -7 -27 -8 -5 -8 14 0 36 4 50 8 20 6 21 8 5 8 -11 0
-33 -4 -50 -8z"/>
<path d="M4928 3453 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6410 3430 c-24 -15 -7 -18 20 -3 14 7 18 13 10 13 -8 0 -22 -4 -30
-10z"/>
<path d="M2266 3405 c16 -14 34 -25 39 -25 12 1 -46 50 -59 50 -5 0 4 -11 20
-25z"/>
<path d="M6320 3419 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5208 3413 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5328 3413 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5597 3413 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M6340 3395 c-14 -8 -20 -14 -15 -14 6 0 21 6 35 14 14 8 21 14 15 14
-5 0 -21 -6 -35 -14z"/>
<path d="M4370 3386 c14 -8 27 -12 29 -10 6 6 -1 11 -29 18 l-25 7 25 -15z"/>
<path d="M2335 3350 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M6080 3329 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M4578 3308 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M6108 3303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4738 3263 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5935 3260 c-13 -6 -15 -9 -5 -9 8 0 22 4 30 9 18 12 2 12 -25 0z"/>
<path d="M4803 3243 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4858 3233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5848 3233 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4945 3220 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5108 3193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5583 3193 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5288 3183 c39 -2 105 -2 145 0 39 1 7 3 -73 3 -80 0 -112 -2 -72 -3z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
